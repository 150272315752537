import Step1Video from "src/videos/tutorials/getting-started/287b83af/Step1-converted.mp4";
import Step2Video from "src/videos/tutorials/getting-started/287b83af/Step2-converted.mp4";
import Step3Video from "src/videos/tutorials/getting-started/287b83af/Step3-converted.mp4";
import Step4Video from "src/videos/tutorials/getting-started/287b83af/Step4-converted.mp4";
import Step5Video from "src/videos/tutorials/getting-started/287b83af/Step5-converted.mp4";
import Step6Video from "src/videos/tutorials/getting-started/287b83af/Step6-converted.mp4";
import Step7Video from "src/videos/tutorials/getting-started/287b83af/Step7-converted.mp4";
import * as React from 'react';
export default {
  Step1Video,
  Step2Video,
  Step3Video,
  Step4Video,
  Step5Video,
  Step6Video,
  Step7Video,
  React
};