import React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { createClient, Provider } from "urql";

import theme from "./src/theme";

const url =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3000/graphql"
    : "/graphql";
const client = createClient({
  url
});

export const wrapPageElement = ({ element }) => {
  return (
    <Provider value={client}>
      <ChakraProvider resetCSS theme={theme}>
        {element}
      </ChakraProvider>
    </Provider>
  );
};
