import Step1Video from "src/videos/tutorials/locations-and-exits/bfe86e48/Step1-converted.mp4";
import Step2Video from "src/videos/tutorials/locations-and-exits/bfe86e48/Step2-converted.mp4";
import Step3Video from "src/videos/tutorials/locations-and-exits/bfe86e48/Step3-converted.mp4";
import Step4Video from "src/videos/tutorials/locations-and-exits/bfe86e48/Step4-converted.mp4";
import Step5Video from "src/videos/tutorials/locations-and-exits/bfe86e48/Step5-converted.mp4";
import * as React from 'react';
export default {
  Step1Video,
  Step2Video,
  Step3Video,
  Step4Video,
  Step5Video,
  React
};