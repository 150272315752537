import { extendTheme } from "@chakra-ui/react";

const starcommand_blue = "#0E79B2";
const cafe_noir = "#452E12";
const perfume_pink = "#D6BCFA";

const primary = starcommand_blue;
const secondary = perfume_pink;

const theme = extendTheme({
  global: {
    h1: {
      fontFamily: "Arvo",
      fontWeight: "extrabold",
      maxWidth: "48rem",
      lineHeight: "1.2",
      letterSpacing: "tight"
    },
    h2: {
      fontFamily: "Arvo",
      lineHeight: "1.2",
      letterSpacing: "tight"
    },
    h3: {
      size: "xl"
    },
    a: {
      color: "purple.200"
    }
  },
  colors: {
    primary,
    secondary,
    cafe_noir,
    starcommand_blue,
    perfume_pink
  },
  fonts: {
    display: "Arvo",
    heading: "Arvo",
    body: '"Montserrat"',
    paragraph: '"Montserrat"',
    company_font: '"Montserrat"'
  },
  styles: {
    h1: {
      fontFamily: "Arvo",
      fontWeight: "extrabold",
      maxWidth: "48rem",
      lineHeight: "1.2",
      letterSpacing: "tight"
    }
  },
  textStyles: {
    paragraph: {
      fontSize: "1.5em",
      lineHeight: "32px",
      letterSpacing: "-0.03em",
      marginBottom: "1em"
    },
    secondary: {
      color: "secondary"
    },
    underline: {
      textDecoration: "underline"
    },
    link: {
      "&:hover": {
        color: "purple.200"
      }
    },
    code: {
      fontFamily: "PT Mono",
      backgroundColor: "#DDD",
      color: "#111",
      padding: "0 5px"
    },
    heading: {
      heading_1: {
        fontFamily: "heading",
        fontSize: "28",
        lineHeight: "34px",
        fontWeight: "2"
      },
      heading_2: {
        fontFamily: "heading",
        fontSize: "24",
        lineHeight: "29px",
        fontWeight: "2"
      },
      heading_3: {
        fontFamily: "heading",
        fontSize: "14",
        lineHeight: "18px",
        fontWeight: "2"
      }
    }
  },
  components: {
    IconButton: {
      variants: {
        whiteIcon: {
          _hover: {
            background: "inherit"
          }
        }
      }
    }
  }
});

export default theme;
