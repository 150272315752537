// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-mdx": () => import("./../../../src/pages/about.mdx" /* webpackChunkName: "component---src-pages-about-mdx" */),
  "component---src-pages-blog-mdx": () => import("./../../../src/pages/blog.mdx" /* webpackChunkName: "component---src-pages-blog-mdx" */),
  "component---src-pages-editor-mdx": () => import("./../../../src/pages/editor.mdx" /* webpackChunkName: "component---src-pages-editor-mdx" */),
  "component---src-pages-help-getting-started-mdx": () => import("./../../../src/pages/help/getting-started.mdx" /* webpackChunkName: "component---src-pages-help-getting-started-mdx" */),
  "component---src-pages-help-index-tsx": () => import("./../../../src/pages/help/index.tsx" /* webpackChunkName: "component---src-pages-help-index-tsx" */),
  "component---src-pages-help-locations-and-exits-mdx": () => import("./../../../src/pages/help/locations-and-exits.mdx" /* webpackChunkName: "component---src-pages-help-locations-and-exits-mdx" */),
  "component---src-pages-help-tag-reference-mdx": () => import("./../../../src/pages/help/tag-reference.mdx" /* webpackChunkName: "component---src-pages-help-tag-reference-mdx" */),
  "component---src-pages-index-feature-tab-tsx": () => import("./../../../src/pages/index/feature-tab.tsx" /* webpackChunkName: "component---src-pages-index-feature-tab-tsx" */),
  "component---src-pages-index-features-tsx": () => import("./../../../src/pages/index/features.tsx" /* webpackChunkName: "component---src-pages-index-features-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-mdx": () => import("./../../../src/pages/privacy.mdx" /* webpackChunkName: "component---src-pages-privacy-mdx" */),
  "component---src-pages-stories-mdx": () => import("./../../../src/pages/stories.mdx" /* webpackChunkName: "component---src-pages-stories-mdx" */),
  "component---src-pages-terms-mdx": () => import("./../../../src/pages/terms.mdx" /* webpackChunkName: "component---src-pages-terms-mdx" */)
}

